import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { EDIT_CAREERS_PAGE_JOB_GROUP } from "services/doverapi/endpointTagsConstants";
import {
  ApiApiCreateCareersPageJobGroupRequest,
  ApiApiDeleteCareersPageJobGroupRequest,
  ApiApiListEditCareersPageJobGroupsRequest,
  ApiApiPartialUpdateCareersPageJobGroupRequest,
  CareersPageJobGroup,
} from "services/openapi";

const careersPageJobGroupAdapter = createEntityAdapter<CareersPageJobGroup>();

const careersPageJobGroup = doverApi.injectEndpoints({
  endpoints: build => ({
    listCareersPageJobGroups: build.query<EntityState<CareersPageJobGroup>, { clientId: string }>({
      queryFn: async ({ clientId }) => {
        // get unauthenticated api client by setting includeToken to false
        const { apiApi: client } = await getOpenApiClients({ includeToken: false });
        try {
          const response = await client.getCareersPageJobGroups({ clientId });
          return {
            data: careersPageJobGroupAdapter.addMany(careersPageJobGroupAdapter.getInitialState(), response),
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listEditCareersPageJobGroups: build.query<Array<CareersPageJobGroup>, ApiApiListEditCareersPageJobGroupsRequest>({
      queryFn: async ({ clientId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const results = await client.listEditCareersPageJobGroups({ clientId });
          return { data: results };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [{ type: EDIT_CAREERS_PAGE_JOB_GROUP }];
      },
    }),
    createCareersPageJobGroup: build.mutation<CareersPageJobGroup, ApiApiCreateCareersPageJobGroupRequest>({
      queryFn: async data => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const res = await client.createCareersPageJobGroup(data);
          return { data: res };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: [{ type: EDIT_CAREERS_PAGE_JOB_GROUP }],
    }),
    partialUpdateCareersPageJobGroup: build.mutation<
      CareersPageJobGroup,
      ApiApiPartialUpdateCareersPageJobGroupRequest
    >({
      queryFn: async data => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const res = await client.partialUpdateCareersPageJobGroup(data);
          return { data: res };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: [{ type: EDIT_CAREERS_PAGE_JOB_GROUP }],
    }),
    deleteCareersPageJobGroup: build.mutation<boolean, ApiApiDeleteCareersPageJobGroupRequest>({
      queryFn: async data => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          await client.deleteCareersPageJobGroup(data);
          return { data: true };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: [{ type: EDIT_CAREERS_PAGE_JOB_GROUP }],
    }),
  }),
});

export const {
  useListCareersPageJobGroupsQuery,
  useListEditCareersPageJobGroupsQuery,
  useCreateCareersPageJobGroupMutation,
  usePartialUpdateCareersPageJobGroupMutation,
  useDeleteCareersPageJobGroupMutation,
} = careersPageJobGroup;
