import { Icon } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue, useAtom } from "jotai";
import React, { useEffect } from "react";
import { useQueryParam, withDefault } from "use-query-params";

import { ReactComponent as ExternalLinkSVG } from "assets/icons/external-link.svg";
import {
  CompanySlugAtom,
  GenerateCareersPageErroredAtom,
  ShowCareersPageGeneration,
} from "components/dover/EditCareersPage/atoms";
import ImportCareersPage from "components/dover/EditCareersPage/ImportCareersPage";
import { LearnMoreLink } from "components/library/LearnMoreLink";
import { BodySmall, Heading } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import PageHelmet from "components/PageHelmet";
import SocialShareButton from "components/SocialShareButton";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import {
  useGetPublishedJobCountQuery,
  useListEditCareersPageJobsQuery,
} from "services/doverapi/endpoints/careersPageJob";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId, useGetClientName } from "services/doverapi/endpoints/client/hooks";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { EditCareersPageFormWrapper } from "views/EditCareersPage/components/EditCareersPageFormWrapper";
import { EditJobGroups } from "views/EditCareersPage/components/EditJobGroups";
import { PublishJobsTable } from "views/EditCareersPage/components/PublishJobsTable";
import { EditCareersPageTabBar } from "views/EditCareersPage/components/TabBar";
import { TalentNetworkBanner } from "views/EditCareersPage/components/TalentNetworkBanner";
import { CAREERS_PAGE_TAB_PARAM, PUBLISH_JOBS_PAGE_LIMIT } from "views/EditCareersPage/constants";
import { EditCareersPageTabEnum, tabParamType } from "views/EditCareersPage/types";

/***** Sub-components *****/

const EditCareersPageHeader = ({
  clientId,
  clientSlug,
  clientName,
}: {
  clientId?: string;
  clientSlug?: string;
  clientName?: string;
}): React.ReactElement => {
  const useSlugPoweredCareersPage = useFeatureFlag(FeatureFlag.SlugPoweredCareersPageUrls);
  const jobsPageUrl = useSlugPoweredCareersPage ? `/jobs/${clientSlug}` : `/dover/careers/${clientId}`;
  const careersPageFullUrl = `https://app.dover.com${jobsPageUrl}`;
  return (
    <Stack direction="row" justifyContent="space-between" flexGrow={1}>
      <Heading>Careers Page</Heading>

      <Stack direction="row" spacing={2} alignItems="center">
        <InternalLink to={`${jobsPageUrl}`} target="_blank" rel="noopener noreferrer">
          <Stack direction="row" spacing={0.7} alignItems="center">
            <Icon Icon={ExternalLinkSVG} color="transparent" />
            <BodySmall color={colors.grayscale.gray500}>Preview</BodySmall>
          </Stack>
        </InternalLink>
        {clientName && (
          <SocialShareButton
            url={careersPageFullUrl}
            menuLabel="Share Careers Page"
            socialPostText={`🚀 We’re hiring! Come work with me at ${clientName}. Check out our open roles here: ${careersPageFullUrl}`}
            emailSubject={`🚀 Join our team at ${clientName}!`}
            emailBody={`Hi there! 

I’m excited to share that we’re hiring at ${clientName}! We have amazing opportunities for talented individuals like you to join our dynamic team. 

Interested? Check out our open positions to find the role that's perfect for you: ${careersPageFullUrl}

We look forward to hearing from you!
              `}
          />
        )}
      </Stack>
    </Stack>
  );
};

const CustomizeTabContent = (): React.ReactElement => {
  const { currentData: clientOnboarding, isFetching: clientOnboardingDataIsLoading } = useGetClientOnboardingQuery();

  const showCareersPageGeneration = useAtomValue(ShowCareersPageGeneration);
  const isErrorGeneratingCareersPage = useAtomValue(GenerateCareersPageErroredAtom);

  if (clientOnboardingDataIsLoading) {
    return <DoverLoadingSpinner />;
  }

  if (!clientOnboarding) {
    return <></>;
  }

  // always show the edit careers page form if there was an error generating the careers page
  if (isErrorGeneratingCareersPage) {
    return <EditCareersPageFormWrapper clientOnboarding={clientOnboarding} isLoading={clientOnboardingDataIsLoading} />;
  }

  if (showCareersPageGeneration) {
    return <ImportCareersPage inOnboardingFlow={false} />;
  }

  // default state is to show the edit form
  return <EditCareersPageFormWrapper clientOnboarding={clientOnboarding} isLoading={clientOnboardingDataIsLoading} />;
};

/***** Main Component *****/

export const EditCareersPage = (): React.ReactElement => {
  const clientId = useGetClientId();
  const [clientSlug, setClientSlug] = useAtom(CompanySlugAtom);
  const clientName = useGetClientName();

  // Tab bar selector
  const [tab, setTab] = useQueryParam(
    CAREERS_PAGE_TAB_PARAM,
    withDefault(tabParamType, EditCareersPageTabEnum.Customize)
  );

  // Set company slug on page load
  const { currentData: clientOnboarding } = useGetClientOnboardingQuery();
  useEffect(() => {
    if (clientOnboarding?.slug) {
      setClientSlug(clientOnboarding.slug);
    }
  }, [clientOnboarding?.slug, setClientSlug]);

  // Publish Jobs queries & values
  const [tablePage, setTablePage] = React.useState(0);
  const { currentData: { publishedJobsCount } = {} } = useGetPublishedJobCountQuery({});
  const {
    currentData: { results: publishableJobs, count: totalJobCount } = {},
    isLoading: isLoadingJobs,
    isFetching: isFetchingJobs,
  } = useListEditCareersPageJobsQuery(clientId ? { clientId, offset: tablePage * PUBLISH_JOBS_PAGE_LIMIT } : skipToken);

  const useCareerPageGroups = useFeatureFlag(FeatureFlag.CareerPageGroups);

  if (!clientOnboarding) {
    return <></>;
  }
  return (
    <Stack
      direction="column"
      p={4}
      width="100%"
      alignItems="center"
      position="relative"
      overflow={tab === EditCareersPageTabEnum.Customize ? "hidden" : undefined}
    >
      <PageHelmet title="Dover Careers Page" />
      <Box maxWidth={{ sm: "710px", xl: "900px" }} width="100%">
        {(clientSlug || clientId) && (
          <EditCareersPageHeader clientId={clientId} clientSlug={clientSlug} clientName={clientName} />
        )}
        <LearnMoreLink
          linkUrl="https://help.dover.com/en/articles/6480726-careers-page"
          description="Build your personalized no-code careers page in minutes using Dover."
        />
        <EditCareersPageTabBar numJobs={publishedJobsCount} selectedTab={tab} setSelectedTab={setTab} />{" "}
        <Box mt={1}>
          {tab === EditCareersPageTabEnum.Customize && <CustomizeTabContent />}
          {tab === EditCareersPageTabEnum.PublishJobs && (
            <PublishJobsTable
              jobs={publishableJobs}
              jobCount={totalJobCount ?? 0}
              jobsLoading={isLoadingJobs || isFetchingJobs}
              clientId={clientId}
              clientName={clientName}
              page={tablePage}
              handlePageChange={setTablePage}
            />
          )}
          {tab === EditCareersPageTabEnum.GroupJobs && useCareerPageGroups && <EditJobGroups />}
          {tab === EditCareersPageTabEnum.TalentNetwork && <TalentNetworkBanner />}
        </Box>
      </Box>
    </Stack>
  );
};
