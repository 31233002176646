import { Icon } from "@doverhq/dover-ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, Box } from "@mui/material";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { useBoolean } from "react-use";
import { z } from "zod";

import { ReactComponent as Add } from "assets/icons/add.svg";
import { ReactComponent as PencilIconSVG } from "assets/icons/pencil-edit.svg";
import { Autocomplete } from "components/library/Autocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { Body, BodySmall, Subtitle1 } from "components/library/typography";
import CustomModal from "components/Modal";
import {
  useCreateCareersPageJobGroupMutation,
  usePartialUpdateCareersPageJobGroupMutation,
} from "services/doverapi/endpoints/careersPageJobGroup";
import { CareersPageJob, CareersPageJobGroup } from "services/openapi";
import { colors } from "styles/theme";
import { JobSchema } from "views/EditCareersPage/types";

const FormSchema = (currentJobGroupNames: string[]): any =>
  z.object({
    name: z
      .string()
      .min(1, { message: "Required" })
      .refine(val => !["ungrouped", "more jobs"].includes(val.toLowerCase()), {
        message: "Invalid group name",
      })
      .refine(
        (val: string) => {
          return !currentJobGroupNames.includes(val);
        },
        {
          message: "A group with this name already exists",
        }
      ),
    jobs: z.array(JobSchema).min(1),
  });
type UpsertJobGroupFormValues = z.infer<ReturnType<typeof FormSchema>>;

interface UpsertJobGroupFormProps {
  jobGroup?: CareersPageJobGroup;
  ungroupedJobs: CareersPageJob[];
  currentJobGroupNames: string[]; // This prop is used in the FormSchema for front end validation to determine which names are already being used, since we can't have duplicates
}

export const UpsertJobGroupModal: React.FC<React.PropsWithChildren<UpsertJobGroupFormProps>> = ({
  jobGroup,
  ungroupedJobs,
  currentJobGroupNames,
}): React.ReactElement => {
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<UpsertJobGroupFormValues>({
    resolver: zodResolver(FormSchema(currentJobGroupNames.filter(name => name !== jobGroup?.name))),
    defaultValues: {
      name: jobGroup?.name ?? "",
      jobs: jobGroup?.jobs.filter(job => job.title) ?? [],
    },
  });
  const [createCareersPageJobGroup] = useCreateCareersPageJobGroupMutation();
  const [partialUpdateCareersPageJobGroup] = usePartialUpdateCareersPageJobGroupMutation();
  const [showModal, setShowModal] = useBoolean(false);
  const { field: nameField } = useController({
    name: "name",
    control,
  });

  const { field: jobsField } = useController({
    name: "jobs",
    control,
  });

  const onSubmit = (): void => {
    if (jobGroup) {
      // @ts-ignore - id will not be undefined here since we filter out the ungrouped job group
      partialUpdateCareersPageJobGroup({ id: jobGroup.id, data: { name: nameField.value, jobs: jobsField.value } })
        .unwrap()
        .then((): void => setShowModal(false));
    } else {
      createCareersPageJobGroup({ data: { name: nameField.value, jobs: jobsField.value } })
        .unwrap()
        .then((): void => setShowModal(false));
    }
  };

  const useOpenModal = (): void => {
    setShowModal(true);
    reset({
      name: jobGroup?.name ?? "",
      // @ts-ignore
      jobs: jobGroup?.jobs.filter(job => job.title) ?? [],
    });
  };

  return (
    <>
      {jobGroup ? (
        <Stack direction="row" spacing={0.5} alignItems="center" onClick={useOpenModal} sx={{ cursor: "pointer" }}>
          <Icon Icon={PencilIconSVG} color="transparent" />
          <BodySmall color={colors.grayscale.gray500}>Edit</BodySmall>
        </Stack>
      ) : (
        <Button variant={ButtonVariant.Primary} onClick={useOpenModal}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Add className="svg-fill" color="white" />
            <BodySmall color="white" weight="500">
              Add
            </BodySmall>
          </Stack>
        </Button>
      )}
      <CustomModal
        onClose={setShowModal}
        customContentStyles={{ borderTop: "none" }}
        maxWidth="sm"
        showTitleSpacer={false}
        open={showModal}
        title={<Subtitle1>{jobGroup ? "Edit Group" : "Add Group"}</Subtitle1>}
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button variant={ButtonVariant.Primary} onClick={handleSubmit(onSubmit)}>
              {jobGroup ? "Save" : "Add"}
            </Button>
          </Stack>
        }
      >
        <Body>
          <Stack width="100%" alignItems="center">
            <form style={{ width: "100%" }}>
              <Subtitle1>Name</Subtitle1>
              <Box pb={0.5}>
                <BodySmall color={colors.grayscale.gray600}>Engineering, Product, Design...etc.</BodySmall>
              </Box>
              <TextField
                error={!!formErrors.name}
                // @ts-ignore - this is due to custom form schema for currentJobGroupNames validation
                errorMessage={formErrors.name?.message}
                fullWidth
                required
                inputProps={{ ...register("name") }}
              />
              <Box pt={2}>
                <Subtitle1>Add Jobs to Group</Subtitle1>
              </Box>
              <Autocomplete
                placeholder={""}
                // @ts-ignore - this is due to custom form schema for currentJobGroupNames validation
                errorText={formErrors.jobs?.message}
                multiple
                initialValues={jobsField.value}
                staticOptions={[...ungroupedJobs, ...(jobGroup?.jobs ?? [])].map(j => ({ id: j.id, title: j.title }))}
                onSelectedOptionsChange={(values: any[]): void => {
                  jobsField.onChange(
                    values.sort((a, b) => {
                      return a.title > b.title ? 1 : -1;
                    })
                  );
                }}
                getOptionLabel={(ungroupedJob: CareersPageJob): string => {
                  return ungroupedJob.title ?? "";
                }}
                filterOptions={(options, { inputValue }): any => {
                  return options.filter(option => option?.title?.toLowerCase()?.includes(inputValue.toLowerCase()));
                }}
                isOptionEqualToValue={(option, value): boolean => option.id === value.id}
              />
            </form>
          </Stack>
        </Body>
      </CustomModal>
    </>
  );
};
