import { Box } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import ChevronRightSVG from "assets/icons/chevron-right.svg";
import MapPinSVG from "assets/icons/map-pin.svg";
import { Spacer } from "components/Spacer";
import { CareersPageJob } from "services/openapi";
import { colors } from "styles/theme";
import { displayJobLocations } from "utils/displayJobLocations";
import { DOVER_CAREERS_PAGE_RECRUITING_SOURCE } from "views/inboundExternal/constants";
import { HeaderTitle, HeaderTitleSmall, JobInfoBox } from "views/inboundExternal/styles";

const JobCardStyle = styled.a`
  text-decoration: none;
  color: inherit;
  width: 100%;
  background-color: white;
  border: 1px solid ${colors.grayscale.gray300};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  padding: 16px 24px;

  &:hover {
    text-decoration: none;
    color: inherit;
    background-color: ${colors.grayscale.gray100};
    cursor: pointer;

    chevronRight > svg {
      transform: translateX(4px);
    }
  }
`;

export const JobCard = ({
  job,
  clientName,
  isEmbed,
  isInGroup,
}: {
  job: CareersPageJob;
  clientName: string;
  isEmbed?: boolean;
  isInGroup?: boolean;
}): React.ReactElement => {
  const [logoUrl] = useQueryParam("logoUrl", StringParam);

  const inboundAppLink = logoUrl
    ? `/apply/${clientName}/${job.id}/?rs=${DOVER_CAREERS_PAGE_RECRUITING_SOURCE}&logoUrl=${logoUrl}`
    : `/apply/${clientName}/${job.id}/?rs=${DOVER_CAREERS_PAGE_RECRUITING_SOURCE}`;

  const showLocation = job?.locations && job.locations.length > 0;
  const locationText = displayJobLocations(job.locations);

  return (
    <JobCardStyle
      key={job.id}
      href={inboundAppLink}
      target={isEmbed ? "_blank" : undefined}
      rel={isEmbed ? "noopener noreferrer" : undefined}
    >
      <Box>
        {isInGroup ? (
          <HeaderTitleSmall>{job.title}</HeaderTitleSmall>
        ) : (
          <HeaderTitle size="sm">{job.title}</HeaderTitle>
        )}
        <Spacer height="8px" />
        {showLocation && (
          <JobInfoBox>
            <ReactSVG src={MapPinSVG} />
            <Spacer width="8px" />
            {locationText}
          </JobInfoBox>
        )}
      </Box>
      <Box>
        <ReactSVG className="chevron-right" src={ChevronRightSVG} />
      </Box>
    </JobCardStyle>
  );
};
