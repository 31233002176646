/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CareersPageJob,
    CareersPageJobFromJSON,
    CareersPageJobFromJSONTyped,
    CareersPageJobToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareersPageJobGroup
 */
export interface CareersPageJobGroup {
    /**
     * 
     * @type {string}
     * @memberof CareersPageJobGroup
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CareersPageJobGroup
     */
    name: string;
    /**
     * 
     * @type {Array<CareersPageJob>}
     * @memberof CareersPageJobGroup
     */
    jobs: Array<CareersPageJob>;
}

export function CareersPageJobGroupFromJSON(json: any): CareersPageJobGroup {
    return CareersPageJobGroupFromJSONTyped(json, false);
}

export function CareersPageJobGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): CareersPageJobGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'jobs': ((json['jobs'] as Array<any>).map(CareersPageJobFromJSON)),
    };
}

export function CareersPageJobGroupToJSON(value?: CareersPageJobGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'jobs': ((value.jobs as Array<any>).map(CareersPageJobToJSON)),
    };
}


