import React from "react";
import styled from "styled-components";
import theme from "styled-theming";

import AlertIconSVG from "assets/icons/alert-red.svg";
import InfoIconSVG from "assets/icons/info-icon.svg";
import SuccessIconSVG from "assets/icons/simple-green-check.svg";
import WarningIconSVG from "assets/icons/warning-outlined.svg";
import { colors } from "styles/theme";

export enum BannerVariant {
  Success = "SUCCESS",
  Warning = "WARNING",
  Info = "INFO",
  Critical = "CRITICAL",
  Gray = "GRAY",
}

const backgroundColor = theme.variants("mode", "variant", {
  [BannerVariant.Success]: { light: colors.primary.light },
  [BannerVariant.Warning]: { light: colors.warning.light },
  [BannerVariant.Info]: { light: colors.informational.light },
  [BannerVariant.Critical]: { light: colors.critical.light },
  [BannerVariant.Gray]: { light: colors.grayscale.gray100 },
});

const getBorderColor = (variant: BannerVariant): string => {
  switch (variant) {
    case BannerVariant.Success:
      return colors.primary.dark;
    case BannerVariant.Warning:
      return colors.warning.dark;
    case BannerVariant.Info:
      return colors.informational.dark;
    case BannerVariant.Critical:
      return colors.critical.dark;
    case BannerVariant.Gray:
      return colors.grayscale.gray300;
    default:
      return colors.grayscale.gray300;
  }
};

interface BannerProps {
  variant: BannerVariant;
  fullWidth?: boolean;
  centeredText?: boolean;
  centeredIcon?: boolean;
  overrideIcon?: React.ReactElement;
  noIcon?: boolean;
  padding?: string;
  noBorder?: boolean;
}

const StyledBanner = styled.div<BannerProps>`
  display: flex;
  width: ${({ fullWidth }): string => (fullWidth ? "100%" : "auto")};
  align-items: ${({ centeredIcon }): string => (centeredIcon ? "center" : "flex-start")};
  justify-content: ${({ centeredText }): string => (centeredText ? "center" : "initial")};
  padding: ${({ padding }): string => padding ?? "16px"};
  background-color: ${backgroundColor};
  border: ${({ noBorder, variant }): string => (noBorder ? "none" : `1px solid ${getBorderColor(variant)}`)};
  border-radius: 4px;
  gap: 16px;
`;

export const Banner: React.FC<React.PropsWithChildren<BannerProps>> = ({
  children,
  variant,
  fullWidth,
  centeredText,
  centeredIcon,
  overrideIcon,
  noIcon,
  padding,
  noBorder,
}) => {
  let icon;

  switch (variant) {
    case BannerVariant.Success:
      icon = SuccessIconSVG;
      break;
    case BannerVariant.Warning:
      icon = WarningIconSVG;
      break;
    case BannerVariant.Info:
      icon = InfoIconSVG;
      break;
    case BannerVariant.Critical:
      icon = AlertIconSVG;
      break;
  }

  return (
    <StyledBanner
      variant={variant}
      fullWidth={fullWidth}
      centeredText={centeredText}
      centeredIcon={centeredIcon}
      padding={padding}
      noBorder={noBorder}
    >
      {noIcon ? <></> : overrideIcon ? overrideIcon : <img src={icon} />}
      {children}
    </StyledBanner>
  );
};
