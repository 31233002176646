/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobLocation,
    JobLocationFromJSON,
    JobLocationFromJSONTyped,
    JobLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareersPageJob
 */
export interface CareersPageJob {
    /**
     * 
     * @type {string}
     * @memberof CareersPageJob
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CareersPageJob
     */
    title?: string | null;
    /**
     * 
     * @type {Array<JobLocation>}
     * @memberof CareersPageJob
     */
    locations?: Array<JobLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof CareersPageJob
     */
    readonly isPublished?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CareersPageJob
     */
    isSample?: boolean;
}

export function CareersPageJobFromJSON(json: any): CareersPageJob {
    return CareersPageJobFromJSONTyped(json, false);
}

export function CareersPageJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): CareersPageJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(JobLocationFromJSON)),
        'isPublished': !exists(json, 'is_published') ? undefined : json['is_published'],
        'isSample': !exists(json, 'is_sample') ? undefined : json['is_sample'],
    };
}

export function CareersPageJobToJSON(value?: CareersPageJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(JobLocationToJSON)),
        'is_sample': value.isSample,
    };
}


