import { Progress } from "@doverhq/dover-ui";
import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";
import { CareersPageJobGroup } from "services/openapi";
import { screenSizes } from "styles/theme";
import { B1_doNotUse } from "styles/typography";
import { JobCard } from "views/inboundExternal/CareersPage/JobCard";
import { HeaderTitle } from "views/inboundExternal/styles";

const JobListStyle = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 789px;
  min-width: 344px;
  height: 100%;

  @media (max-width: ${screenSizes.tablet}) {
    min-width: auto;
    max-width: 100%;
  }
`;

const GroupTitle = styled(HeaderTitle)`
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

interface JobListProps {
  jobGroups: CareersPageJobGroup[];
  clientName?: string;
  isJobsLoading: boolean;
  isEmbed?: boolean;
}

export const JobList: React.FC<JobListProps> = ({ jobGroups, clientName, isJobsLoading, isEmbed }) => {
  const totalJobs = jobGroups.reduce((sum, group) => sum + (group.jobs?.length || 0), 0);
  const displayGroups = jobGroups.length > 1;

  return (
    <JobListStyle>
      {isJobsLoading ? (
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
          <Progress size="large" />
        </Box>
      ) : totalJobs > 0 && clientName ? (
        <>
          {!isEmbed && (
            <>
              <HeaderTitle size="sm">
                Open Positions
                {totalJobs >= 5 && ` (${totalJobs})`}
              </HeaderTitle>
              <Spacer height={16} />
            </>
          )}{" "}
          {jobGroups.map(
            group =>
              group.jobs?.length > 0 && (
                <>
                  {displayGroups && <GroupTitle size="sm">{group.id ? group.name : "More Jobs"}</GroupTitle>}
                  {group.jobs.map(job => (
                    <JobCard job={job} clientName={clientName} isEmbed={isEmbed} isInGroup={displayGroups} />
                  ))}
                </>
              )
          )}
        </>
      ) : (
        <B1_doNotUse>No positions available at this time</B1_doNotUse>
      )}
    </JobListStyle>
  );
};
