import { Stack, Box } from "@mui/material";
import React from "react";

import { BodySmall, Overline } from "components/library/typography";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { colors } from "styles/theme";
import { EditCareersPageTabEnum } from "views/EditCareersPage/types";

interface TabProps {
  tabOption: EditCareersPageTabEnum;
  selectedTab?: EditCareersPageTabEnum;
  numJobs?: number;
  setSelectedTab: (selectedTab: EditCareersPageTabEnum) => void;
}

const EditCareersPageTab = ({ tabOption, selectedTab, numJobs, setSelectedTab }: TabProps): React.ReactElement => {
  const label = tabOption;
  const isSelected = selectedTab === tabOption || (!selectedTab && tabOption === EditCareersPageTabEnum.Customize);

  return (
    <Stack
      direction="row"
      spacing={1}
      onClick={(): void => setSelectedTab(tabOption)}
      color={isSelected ? colors.black : colors.grayscale.gray400}
      borderBottom={isSelected ? `2px solid ${colors.primary.base}` : "none"}
      px={2}
      paddingBottom={1}
      justifyContent="center"
      alignItems="center"
      sx={{ cursor: "pointer" }}
    >
      <>
        <BodySmall color={isSelected ? colors.black : colors.grayscale.gray500} weight={isSelected ? "600" : undefined}>
          {label}
        </BodySmall>
        {numJobs !== undefined && <CountBubble numJobs={numJobs} isSelected={isSelected} />}
      </>
    </Stack>
  );
};

interface CountBubbleProps {
  numJobs: number;
  isSelected?: boolean;
}

const CountBubble = ({ numJobs, isSelected }: CountBubbleProps): React.ReactElement => {
  return (
    <Box
      display="flex"
      borderRadius="100px"
      bgcolor={colors.grayscale.gray200}
      px={1}
      py={0.5}
      alignItems="center"
      justifyContent="center"
    >
      <Overline color={isSelected ? colors.black : colors.white}>{numJobs}</Overline>
    </Box>
  );
};

export const EditCareersPageTabBar = ({
  selectedTab,
  numJobs,
  setSelectedTab,
}: {
  selectedTab?: EditCareersPageTabEnum;
  numJobs?: number;
  setSelectedTab: (selectedTab: EditCareersPageTabEnum) => void;
}): React.ReactElement => {
  const useCareerPageGroups = useFeatureFlag(FeatureFlag.CareerPageGroups);

  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      spacing={3}
      paddingTop={3}
      borderBottom={`1px solid ${colors.grayscale.gray200}`}
    >
      <EditCareersPageTab
        tabOption={EditCareersPageTabEnum.Customize}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <EditCareersPageTab
        tabOption={EditCareersPageTabEnum.PublishJobs}
        selectedTab={selectedTab}
        numJobs={numJobs}
        setSelectedTab={setSelectedTab}
      />
      {useCareerPageGroups && (
        <EditCareersPageTab
          tabOption={EditCareersPageTabEnum.GroupJobs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}
    </Stack>
  );
};
