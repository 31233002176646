import { Button, Icon } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { useBoolean } from "react-use";

import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import { ReactComponent as TrashSVG } from "assets/icons/trash.svg";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall, BodyExtraSmall, Subtitle1 } from "components/library/typography";
import CustomModal from "components/Modal";
import { useDeleteCareersPageJobGroupMutation } from "services/doverapi/endpoints/careersPageJobGroup";
import { CareersPageJob } from "services/openapi";
import { colors } from "styles/theme";
import { UpsertJobGroupModal } from "views/EditCareersPage/components/UpsertJobGroupModal";

export const JobGroupCell = (params: GridRenderCellParams): React.ReactElement => {
  return (
    <Stack>
      <Box sx={{ textWrap: "wrap" }}>
        <BodySmall weight="600">{params.row.name}</BodySmall>
      </Box>
    </Stack>
  );
};

export const JobGroupJobsCell = (params: GridRenderCellParams): React.ReactElement => {
  return (
    <Stack>
      <Box sx={{ wordBreak: "break-word", textWrap: "wrap" }}>
        <BodyExtraSmall color={colors.grayscale.gray600} style={{ lineHeight: "20px" }}>
          {params.row.jobs.map((job: CareersPageJob) => job.title).join(", ")}
        </BodyExtraSmall>
      </Box>
    </Stack>
  );
};

export const JobGroupActionsCell = (params: GridRenderCellParams): React.ReactElement => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useBoolean(false);
  const [deleteCareersPageJobGroup] = useDeleteCareersPageJobGroupMutation();

  const onDelete = async (): Promise<void> => {
    await deleteCareersPageJobGroup({ id: params.row.id })
      .unwrap()
      .then((): void => setShowDeleteConfirmation(false));
  };

  return (
    <Stack direction="row" spacing={2}>
      {params.row.id ? (
        <>
          <UpsertJobGroupModal
            jobGroup={params.row}
            ungroupedJobs={params.row.ungroupedJobs}
            currentJobGroupNames={params.row.currentJobGroupNames}
          />
          <Stack
            direction="row"
            spacing={0.7}
            alignItems="center"
            onClick={setShowDeleteConfirmation}
            sx={{ cursor: "pointer" }}
          >
            <Icon Icon={TrashSVG} color="critical-base" />
            <BodySmall color={colors.critical.base}>Delete</BodySmall>
          </Stack>
          <CustomModal
            maxWidth="sm"
            customContentStyles={{ borderTop: "none" }}
            open={showDeleteConfirmation}
            onClose={setShowDeleteConfirmation}
            title={<Subtitle1>{`Delete Group: ${params.row.name}`}</Subtitle1>}
            dialogActions={
              <Stack direction="row" spacing={1}>
                <Button variant="criticalFilled" onPress={onDelete}>
                  <Stack direction="row" width="71px" justifyContent="center">
                    Delete
                  </Stack>
                </Button>
              </Stack>
            }
          >
            <Body color={colors.grayscale.gray600}>
              This group will be deleted. All assigned jobs will be ungrouped.
            </Body>
          </CustomModal>
        </>
      ) : (
        <Tooltip
          title={
            <Stack width="208px" direction="row" spacing={1} justifyContent="center">
              <BodyExtraSmall color={"#F0F3F5"}>
                Ungrouped jobs will be listed under <strong>More Jobs</strong> on the careers page
              </BodyExtraSmall>
            </Stack>
          }
          placement="top"
        >
          <AlertIcon />
        </Tooltip>
      )}
    </Stack>
  );
};
