import { createEnumParam } from "use-query-params";
import { z } from "zod";

export enum EditCareersPageTabEnum {
  Customize = "Customize",
  PublishJobs = "Publish Jobs",
  GroupJobs = "Group Jobs",
  TalentNetwork = "Talent Network (Beta)",
}

export const tabParamType = createEnumParam(Object.values(EditCareersPageTabEnum));

export const JobSchema = z.object({
  id: z.string(),
  title: z.string(),
});
