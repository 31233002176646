import { Box, Stack, styled } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridColumnHeaderParams,
  GridRowHeightReturnValue,
} from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useMemo } from "react";

import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { Banner, BannerVariant } from "components/library/Banner";
import { Body, BodySmall, OverlineSmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useListEditCareersPageJobGroupsQuery } from "services/doverapi/endpoints/careersPageJobGroup";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { CareersPageJobGroup } from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { TableWrapper } from "views/CompanySetup/components/Notifications/styles";
import {
  JobGroupActionsCell,
  JobGroupCell,
  JobGroupJobsCell,
} from "views/EditCareersPage/components/EditJobGroupsTableCells";
import { UpsertJobGroupModal } from "views/EditCareersPage/components/UpsertJobGroupModal";

const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-cell {
    padding: 10px;
  }
`;

type JobGroupRow = {
  id: string;
  name: string;
  jobs: CareersPageJobGroup["jobs"];
  ungroupedJobs: CareersPageJobGroup["jobs"];
  currentJobGroupNames: string[];
};

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "GROUPS",
    renderHeader: (params: GridColumnHeaderParams): React.ReactElement => (
      <OverlineSmall color={colors.grayscale.gray600}>{params.colDef.headerName}</OverlineSmall>
    ),
    flex: 0,
    minWidth: 150,
    sortable: false,
    cellClassName: "t-cell",
    headerClassName: "h-cell",
    renderCell: (params: GridRenderCellParams): React.ReactElement => <JobGroupCell {...params} />,
  },
  {
    field: "jobs",
    headerName: "JOBS",
    flex: 1,
    sortable: false,
    cellClassName: "t-cell",
    headerClassName: "h-cell",
    renderHeader: (params: GridColumnHeaderParams): React.ReactElement => (
      <OverlineSmall color={colors.grayscale.gray600}>{params.colDef.headerName}</OverlineSmall>
    ),
    renderCell: (params: GridRenderCellParams): React.ReactElement => <JobGroupJobsCell {...params} />,
  },
  {
    field: "actions",
    headerName: "ACTIONS",
    flex: 0,
    minWidth: 150,
    sortable: false,
    cellClassName: "t-cell",
    headerClassName: "h-cell",
    renderHeader: (params: GridColumnHeaderParams): React.ReactElement => (
      <OverlineSmall color={colors.grayscale.gray600}>{params.colDef.headerName}</OverlineSmall>
    ),
    renderCell: (params: GridRenderCellParams): React.ReactElement => <JobGroupActionsCell {...params} />,
  },
];

interface JobGroupsTableProps {
  isLoadingJobGroups: boolean;
  isFetchingJobGroups: boolean;
  jobGroups: CareersPageJobGroup[] | undefined;
  ungroupedJobs: CareersPageJobGroup["jobs"];
  currentJobGroupNames: string[];
}

const JobGroupsTable: React.FC<JobGroupsTableProps> = ({
  isLoadingJobGroups,
  isFetchingJobGroups,
  jobGroups,
  ungroupedJobs,
  currentJobGroupNames,
}) => {
  const jobGroupRows = useMemo((): JobGroupRow[] => {
    return jobGroups && !isLoadingJobGroups
      ? jobGroups.map((jobGroup: CareersPageJobGroup) => ({
          id: jobGroup.id ?? "",
          name: jobGroup.name,
          jobs: jobGroup.jobs,
          ungroupedJobs: ungroupedJobs,
          currentJobGroupNames: currentJobGroupNames,
        }))
      : [];
  }, [jobGroups, isLoadingJobGroups, ungroupedJobs, currentJobGroupNames]);

  if (isFetchingJobGroups || !jobGroups) {
    return (
      <Box display="flex" justifyContent="center">
        <DoverLoadingSpinner minHeight="200px" spinnerSize="64px" width="64px" height="200px" />
      </Box>
    );
  }

  return (
    <>
      {ungroupedJobs.length !== 0 && (
        <Banner variant={BannerVariant.Gray} overrideIcon={<InfoIcon />} centeredIcon>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <BodySmall>You have ungrouped jobs on your careers page. See details in the table below.</BodySmall>
          </Stack>
        </Banner>
      )}
      <TableWrapper>
        <StyledDataGrid
          loading={isLoadingJobGroups || isFetchingJobGroups}
          autoHeight
          headerHeight={40}
          getRowHeight={(): GridRowHeightReturnValue => "auto"}
          rows={jobGroupRows}
          columns={columns}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          hideFooter
        />
      </TableWrapper>
    </>
  );
};

export const EditJobGroups = (): React.ReactElement => {
  const clientId = useGetClientId();

  const {
    currentData: jobGroups,
    isLoading: isLoadingJobGroups,
    isFetching: isFetchingJobGroups,
  } = useListEditCareersPageJobGroupsQuery(clientId ? { clientId } : skipToken);

  const ungroupedJobs = useMemo(() => {
    return jobGroups?.filter(group => !group.id)[0]?.jobs ?? [];
  }, [jobGroups]);

  const currentJobGroupNames = useMemo(() => {
    return jobGroups?.map(group => group.name) ?? [];
  }, [jobGroups]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Stack spacing={1}>
          <Body weight="500">Group Jobs</Body>
          <BodySmall weight="400" color={colors.grayscale.gray500} style={{ margin: 0 }}>
            Add sections to your careers page by grouping jobs by team, location, and more.{" "}
            <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              color={colors.link}
              href="https://help.dover.com/en/articles/10874814-careers-page-groups"
            >
              Learn more
            </ExternalLink>
          </BodySmall>
        </Stack>
        <UpsertJobGroupModal ungroupedJobs={ungroupedJobs} currentJobGroupNames={currentJobGroupNames} />
      </Stack>
      <JobGroupsTable
        isLoadingJobGroups={isLoadingJobGroups}
        isFetchingJobGroups={isFetchingJobGroups}
        jobGroups={jobGroups}
        ungroupedJobs={ungroupedJobs}
        currentJobGroupNames={currentJobGroupNames}
      />
    </Stack>
  );
};
